<section>
  <div class="container-fluid">
    <div class="col-12">
      <hr class="my-4 my-md-5 border-secondary" />
    </div>

    <div class="" *ngIf="event?.description?.trim()">
      <div class="row justify-content-between align-items-top">
        <div class="col">
          <div class="card-body m-2">
            <article>
              <header>
                <h3 class="h4 mb-3">Sobre el evento</h3>
              </header>
              <div
                class="description small text-muted"
                [innerText]="event?.description"
              ></div>
            </article>
          </div>
        </div>
        <div class="col-12 d-block d-md-none">
          <hr class="my-4 my-md-5 border-secondary" />
        </div>
        <div class="col-12 col-md-6 border-start">
          <div class="card-body m-2">
            <aside class="aside-event-vendor">
              <header class="d-flex align-items-center mb-3">
                <figure
                  class="aside-vendor-picture rounded-circle border-secondary mb-0 me-3 border"
                >
                  <img [src]="profileImage" alt="" />
                </figure>
                <div class="vendor d-grid">
                  <h3 class="h4 m-0">{{ event?.vendor?.name }}</h3>
                </div>
              </header>
              <div class="description small text-muted">
                <p>{{ returnPolicyMessage }}</p>
              </div>
              <button
                class="btn btn-outline-dark w-100 rounded rounded-pill"
                data-bs-toggle="modal"
                data-bs-target="#vender_form"
              >
                Enviar mensaje
              </button>
            </aside>
          </div>
        </div>
      </div>
    </div>

    <div class="" *ngIf="!event?.description?.trim()">
      <div class="row">
        <div class="col-12">
          <div class="card-body m-2">
            <aside
              class="d-grid d-lg-flex justify-content-between align-items-center"
            >
              <header class="d-flex align-items-center w-auto">
                <figure
                  class="aside-vendor-picture rounded-circle border-secondary mb-0 me-3 border"
                >
                  <img [src]="profileImage" alt="" />
                </figure>
                <div class="vendor d-grid">
                  <h3 class="h4 m-0">{{ event?.vendor?.name }}</h3>
                </div>
              </header>
              <div class="description px-lg-4 small text-muted col">
                <p class="my-3 my-lg-0 w-100">{{ returnPolicyMessage }}</p>
              </div>
              <button
                class="btn btn-outline-dark col-auto rounded rounded-pill px-4"
                data-bs-toggle="modal"
                data-bs-target="#vender_form"
              >
                Enviar mensaje
              </button>
            </aside>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <hr class="my-4 my-md-5 border-secondary" />
    </div>
  </div>
</section>

<!-- Modal vendor form  -->
<div
  class="modal fade"
  id="vender_form"
  tabindex="-1"
  aria-labelledby="vender_formLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="vender_formLabel">Enviar mensaje</h1>
        <button
          #close_modal
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body m-0">
        <div class="rounded bg-light p-3">
          <header class="d-flex align-items-center">
            <figure
              class="aside-vendor-picture rounded-circle border-secondary mb-0 me-3 border"
            >
              <img [src]="profileImage" alt="" />
            </figure>
            <div class="vendor d-grid">
              <h3 class="h4 m-0">{{ event?.vendor?.name }}</h3>
            </div>
          </header>
        </div>
      </div>
      <hr class="border-secondary p-0 m-0" />
      <div class="modal-body">
        <form
          [formGroup]="sendMessageForm"
          id="send-message-form"
          (submit)="handleSubmit()"
        >
          <div class="h6 mb-2">Datos de contacto</div>
          <div class="mb-3 form-floating">
            <input
              appFormInput
              formControlName="fullName"
              type="text"
              class="form-control"
              id="fullNameInput"
              placeholder="Nombre y apellidos"
            />
            <label for="fullNameInput" class="form-label"
              >Nombre completo</label
            >
          </div>
          <div class="mb-3 form-floating">
            <input
              appFormInput
              formControlName="email"
              type="email"
              class="form-control"
              id="emailInput"
              placeholder="name@example.com"
            />
            <label for="emailInput" class="form-label"
              >Correo electrónico</label
            >
            <div class="invalid-feedback" *ngIf="emailInvalid">
              Este no es un correo válido
            </div>
          </div>
          <div class="mb-3 form-floating">
            <input
              appFormInput
              formControlName="phone"
              type="tel"
              class="form-control"
              id="phoneInput"
              placeholder="+1(809)888-8888"
            />
            <label for="phoneInput" class="form-label">Teléfono</label>
          </div>
          <hr />
          <div class="mb-3 form-floating">
            <textarea
              appFormInput
              formControlName="comment"
              class="form-control"
              placeholder="Leave a comment here"
              id="commentInput"
              style="height: 200px"
            ></textarea>
            <label for="commentInput">Comentario</label>
          </div>
        </form>
      </div>
      <div class="modal-footer justify-content-between">
        <button
          type="button"
          class="btn btn-outline-dark rounded-pill px-3"
          data-bs-dismiss="modal"
        >
          Cancelar
        </button>
        <button
          form="send-message-form"
          type="submit"
          class="btn btn-dark rounded-pill px-4"
          [disabled]="sendMessageForm.invalid"
        >
          Enviar mensaje
        </button>
      </div>
    </div>
  </div>
</div>
<core-loading message="Enviando mensaje"></core-loading>
