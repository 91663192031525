<div class="card border-0 shadow-sm rounded-tix small mb-4">
  <div
    class="card-header bg-white border-bottom rounded-tix py-3"
    style="
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    "
  >
    <h6 class="m-0">{{ getTitle() }}</h6>
  </div>
  <ul class="list-group rounded-0 border-0 rounded-tix text-dark">
    <li
      class="list-group-item border-0 border-bottom text-muted d-flex justify-content-between align-items-center py-3"
      *ngFor="let ticket of tickets ?? []"
    >
      <div class="ticket_information">
        <strong>Ticket ID: {{ ticket.id }}</strong>
        <span
          class="badge rounded-pill ms-2"
          [ngClass]="{
            'text-bg-success': ticket.checkInDate === null,
            'text-bg-danger': ticket.checkInDate !== null || orderCancelled
          }"
          >{{
            orderCancelled
              ? "Cancelado"
              : ticket.checkInDate !== null
              ? "Usado"
              : "Disponible"
          }}</span
        >
        <br />
        {{ ticket.ticketType.data.attributes.name.toUpperCase() }} <br />
        {{ ticket.isSeat ? ticket.label : "" }}
        <div *ngIf="ticket.gift" class="gift_information rounded-pill px-1">
          <img
            *ngIf="ticket.gift"
            height="25px"
            [src]="getGiftPicture(ticket.gift?.attributes?.name)"
            class="object-fit-contain rounded-pill"
          />
          <div
            class="badge rounded-pill ms-2"
            style="background: #f9f2e8 !important; color: #000 !important"
          >
            {{ ticket.gift?.attributes?.name }}
          </div>
        </div>
      </div>
      <button
        *ngIf="!orderCancelled"
        class="btn btn-dark rounded-pill btn-sm py-1 px-3 me-1 border d-flex justify-content-between gap-2 align-items-center"
        data-bs-toggle="modal"
        data-bs-target="#qr_ticket"
        (click)="onClickSeeQR(ticket)"
      >
        <span class="small text-uppercase"> Ver QR </span>
        <span class="material-symbols-outlined p-0 m-0" style="font-size: 24px"
          >qr_code_scanner</span
        >
      </button>
    </li>
  </ul>
</div>

<div class="modal fade" id="qr_ticket" tabindex="-1" aria-hidden="true">
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="vender_formLabel">
          Ticket {{ this.ticketData?.ticketId }}
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body m-0" #ticketPrint>
        <order-ticket [data]="ticketData"></order-ticket>
      </div>
      <div
        class="modal-footer justify-content-between align-items-center d-flex"
      >
        <div class="w-100 d-flex justify-content-end">
          <button
            [disabled]="isLoading"
            (click)="downloadTicket()"
            class="bg-black btn-lg btn-dark btn d-flex align-items-center gap-2 justify-content-center"
          >
            <span class="material-symbols-outlined">picture_as_pdf</span>
            <span class="d-none d-md-block">Descargar PDF</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
